import React from 'react'

export default function Portfolio() {
  return (
    <div className='PortFullScreen'>
      <div className="PortContainer">
        <div className="PortTitle">
          <h4>PORTFOLIO</h4>
          <h2>Some of my most recent projects</h2>
        </div>
        <div className="PorjectGroup">
          <div className="PortList">
            <div className="PortName"><button>ALL</button></div>
            <div className="PortName"><button>WEBSITE</button></div>
            <div className="PortName"><button>APP</button></div>
            <div className="PortName"><button>GAME</button></div>
            <div className="PortName"><button>EVENT</button></div>
          </div>
          <div className="PortData">

          </div>
        </div>
      </div>    
    </div>
  )
}
