import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";

export default function Resume() {
    return (
        <div className='ResFullScreen'>
            <div className="ResContainer">
                <div className="ResumeTitleName">
                    <div className="ResumeTitle">
                        <p>Resume</p>
                    </div>
                    <h2>A summary of My Resume</h2>
                </div>

                <div className="MyEdEx">
                    <div className="EdExGroup">
                        <h3>My Education</h3>
                        <div className="EdExData">
                                <div className="EdExInfo">
                                    <h4>Higher Diploma in Animation and Game Design</h4>
                                    <p>September 2019 - June 2021</p>
                                    <span>This project allows me to use cutting-edge computer systems and software to realize my creative ideas for producing digital entertainment products.</span>
                                </div>
                                <div className="EdExInfo">
                                    <h4>Foundation Diploma in IT</h4>
                                    <p>September 2018 - June 2019</p>
                                    <span>Allowing me to gain the professional skills needed to develop a career in the IT industry and provide me with a solid foundation for further study. The program developed my oral and written language skills, enhanced my communication and presentation skills, and enabled me to have a successful career in the IT industry.</span>
                                </div>
                            </div>
                    </div>
                    <div className="EdExGroup">
                        <h3>My Experience</h3>
                        <div className="EdExData">
                            <div className="EdExInfo">
                                <h4>Full-stack And Game Developer</h4>
                                <p>Dragon Creative Media Ltd. / 2022-2024</p>
                                <span>The company is a multimedia company that is responsible for helping customers create software programs or games with different needs, such as programs needed by some large shopping malls and governments.</span>
                            </div>
                            <div className="EdExInfo">
                                <h4>Front-end Developer</h4>
                                <p>Pangu by Kenal / 2021-2022</p>
                                <span>Create a website about any of the company sandbox jobs</span>
                            </div>
                            <div className="EdExInfo">
                                <h4>Software Developer</h4>
                                <p>Shiji Group / 2021</p>
                                <span>Develop catering systems, such as self-service ordering systems</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="MySkillsCon">
                    <h2>My Skills</h2>
                    <div className="MySkillGroup">
                        <div className="MySkillList">
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Web Design</h3>
                                    <div className="SkillProgress"><ProgressBar completed={60}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>JavaScript</h3>
                                    <div className="SkillProgress"><ProgressBar completed={50}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>HTML/CSS</h3>
                                    <div className="SkillProgress"><ProgressBar completed={70}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Python</h3>
                                    <div className="SkillProgress"><ProgressBar completed={40}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                        </div>
                        <div className="MySkillList">
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>React JS</h3>
                                    <div className="SkillProgress"><ProgressBar completed={65}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Java</h3>
                                    <div className="SkillProgress"><ProgressBar completed={40}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Laravel PHP</h3>
                                    <div className="SkillProgress"><ProgressBar completed={80}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Bootstrap</h3>
                                    <div className="SkillProgress"><ProgressBar completed={50}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
